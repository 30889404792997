<template>
  <div class="empty-state-component">
    <img src="@/assets/images/emptyState/no-datas.png" alt="" />
    <span class="state-message">
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: "empty-state-component",
  props: {
    message: {
      type: String,
      default() {
        return "暂无内容";
      },
    },
  },
};
</script>

<style lang="less" scoped>
.empty-state-component {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .state-message {
    font-weight: 400;
    font-size: 12px;
    color: #b5bece;
  }
}
</style>
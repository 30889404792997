<template>
  <div class="params-template-config">
    <div class="title-line">
      <span class="title-label"> 模板 </span>
      <span class="buttons-part">
        <r-button>复用参数</r-button>
        <r-button plain>编辑模板 </r-button>
      </span>
    </div>
    <div class="template-form">
      <EmptyState />
    </div>
  </div>
</template>

<script>
import EmptyState from '@/components/EmptyState'

export default {
  name: "params-template-config",
  components: {
    EmptyState
  }
};
</script>

<style lang="less" scoped>
.params-template-config {
  height: 100%;
  width: 100%;
  padding: 0 20px 20px;
  .title-line {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e7eb;
    .title-label {
      font-weight: 600;
      font-size: 16px;
      color: #252d3d;
    }
  }
  .template-form {
    height: calc(100% - 78px);
    width: 100%;
    margin-top: 20px;
  }
}
</style>
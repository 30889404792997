<template>
  <div class="device-params-template">
    <div class="tree-part">
      <CategorySetTree
        ref="categoryTreeIns"
        type="asset"
        source="paramsTemplate"
        @updateTreeData="treeDataUpdater"
      />
    </div>
    <div class="template-part">
      <ParamsTemplateConfig />
    </div>
  </div>
</template>

<script>
import CategorySetTree from "../../components/CategorySetTree";
import ParamsTemplateConfig from "./components/paramsTemplateConfig";
export default {
  name: "device-params-template",
  components: {
    CategorySetTree,
    ParamsTemplateConfig,
  },
  methods: {
    treeDataUpdater(item) {
      console.log("xxx", item);
    },
  },
};
</script>

<style lang="less" scoped>
.device-params-template {
  height: calc(100vh - 90px);
  margin: 10px 30px;
  display: flex;
  gap: 20px;
  .tree-part {
    width: 440px;
    height: 100%;
    background: #ffffff;
    border-radius: 10px;
  }
  .template-part {
    flex: 1;
    background: #ffffff;
    border-radius: 10px;
    overflow: auto;
  }
}
</style>
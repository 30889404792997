var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "params-template-config" }, [
    _c("div", { staticClass: "title-line" }, [
      _c("span", { staticClass: "title-label" }, [_vm._v(" 模板 ")]),
      _c(
        "span",
        { staticClass: "buttons-part" },
        [
          _c("r-button", [_vm._v("复用参数")]),
          _c("r-button", { attrs: { plain: "" } }, [_vm._v("编辑模板 ")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "template-form" }, [_c("EmptyState")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
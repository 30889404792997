var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty-state-component" }, [
    _c("img", {
      attrs: {
        src: require("@/assets/images/emptyState/no-datas.png"),
        alt: "",
      },
    }),
    _c("span", { staticClass: "state-message" }, [
      _vm._v("\n    " + _vm._s(_vm.message) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }